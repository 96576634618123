<template>
  <v-container>
    <v-row>
      <h1 class="headline">
        Мои задачи
      </h1>
    </v-row>
    <event-list :eventsGroups="eventsGroups" />
  </v-container>
</template>

<script>
import EventList from "@/components/EventsList.vue";
export default {
  components: {
    EventList
  },
  data: () => ({
    eventsGroups: [
      {
        name: "global",
        displayName: "Без даты",
        color: "blue lighten-5",
        filter: x => {
          //return !x.resolution || x.resolution == "confirmed";
          return true || x;
        }
      },
      {
        name: "yesterday",
        displayName: "Вчера",
        color: "red lighten-5",
        filter: x => {
          return true || x;
        }
      },
      {
        name: "today",
        displayName: "Сегодня",
        color: "teal lighten-5",
        filter: x => {
          return true || x;
        }
      },
      {
        name: "tomorrow",
        displayName: "Завтра",
        color: "lime lighten-5",
        filter: x => {
          return true || x;
        }
      }
    ]
  })
};
</script>
